import { TIDY_CAL_DEMO_URL } from '$lib/constants';
import * as Sentry from '@sentry/sveltekit';
import { redirect, type Handle } from '@sveltejs/kit';
import { sequence } from '@sveltejs/kit/hooks';
import { HttpStatusCode } from 'axios';

Sentry.init({
	dsn: 'https://a2cbbd2383064614bee604f3f58350ea@o4505608389459968.ingest.sentry.io/4505608476426240',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [new Sentry.Replay()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});
const redirectDemoBookingPage: Handle = async ({ event, resolve }) => {
	const { url } = event;
	if (url.pathname?.startsWith('/request-demo')) {
		throw redirect(HttpStatusCode.TemporaryRedirect, TIDY_CAL_DEMO_URL);
	} else {
		return resolve(event);
	}
};

export const handle = sequence(redirectDemoBookingPage);
export const handleError = Sentry.handleErrorWithSentry();
