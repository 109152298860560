import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [4,2,5,3];

export const dictionary = {
		"/(marketing)": [~13,[4]],
		"/(marketing)/about": [14,[4]],
		"/(admin)/admin/form_log": [~7,[2]],
		"/(admin)/admin/subscriptions": [~8,[2]],
		"/(admin)/admin/users": [~9,[2]],
		"/(marketing)/block-emails/[token]": [~15,[4]],
		"/(marketing)/cookie-policy": [16,[4]],
		"/(marketing)/disclaimers": [17,[4]],
		"/(marketing)/facets": [18,[5]],
		"/(marketing)/facets/[facet]": [19,[5]],
		"/(marketing)/facets/[facet]/[...filters]": [~20,[5]],
		"/(marketing)/faq": [21,[4]],
		"/(marketing)/guide": [22,[4]],
		"/(marketing)/meeting-booked/[meetingname]": [23,[4]],
		"/(marketing)/pricing": [~24,[4]],
		"/(marketing)/privacy": [25,[4]],
		"/(app)/queries": [~11,[3]],
		"/(admin)/scrape": [~10,[2]],
		"/(marketing)/search": [26,[4]],
		"/(app)/settings": [12,[3]],
		"/(marketing)/sign-in": [~27,[4]],
		"/(marketing)/sign-out": [28,[4]],
		"/(marketing)/sign-up": [~29,[4]],
		"/(marketing)/support": [30,[4]],
		"/(marketing)/terms-conditions": [31,[4]],
		"/test-emails": [35,[6]],
		"/(marketing)/thank-you/[formname]": [32,[4]],
		"/(marketing)/upgrade": [~33,[4]],
		"/(marketing)/verify-login": [~34,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';